<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Couriers' }, { name: 'Listado' }]" />
    <v-card>      
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Listado de Couriers</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CreateCourier' }" color="primary">
         <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Courier
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-data-table
          :headers="[
            { text: 'Nombre', value: 'nombre' },
            { text: 'Rut', value: 'rut' },
            { text: 'Representante Legal', value: 'representante_legal_courier' },
            { text: 'Estado', value: 'estado' },
            { text: 'Acciones' }
          ]"
          :items="couriers"
          :loading="loadingCouriers"
          :rows-per-page-items="[10, 25, 35, 50]"
          class="elevation-1"
        >
          <tr slot="items" slot-scope="props">
            <td class="px-3">
              {{ props.item.nombre }}
            </td>
            <td class="px-3">
              {{ props.item.rut }}
            </td>
            <td class="px-3">
              {{ props.item.representante_legal_courier }}
            </td>
            <td class="px-3">
              <v-chip v-if="props.item.estado === true" small color="primary" text-color="white">
                ACTIVO
              </v-chip>
              <v-chip v-else-if="props.item.estado === false" small>
                INACTIVO
              </v-chip>
            </td>
            <td class="px-3">
              <v-btn
                class="ma-0"
                :to="{ name: 'EditCourier', params: { id: props.item.id } }"
                small
                icon
                flat
                color="info"
              >
                <v-icon small>
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                class="ma-0"
                @click="openModalDeleteCourier(props.item)"
                small
                icon
                flat
                color="error"
              >
                <v-icon small>
                  delete
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </v-data-table>
      </v-container>
    </v-card>
    <ModalDelete @onChange="getCouriers" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de Couriers" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/courier/ModalDelete")
  },
  data() {
    return {};
  },

  computed: {
    ...mapState({
      couriers: state => state.couriers.couriers,
      loadingCouriers: state => state.couriers.loadingCouriers
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getCouriers();
  },
  methods: {
    ...mapActions({
      getCouriers: "couriers/getCouriers",
      replaceShowModalDeleteCourier: "couriers/replaceShowModalDeleteCourier",
      replaceCurrentCourier: "couriers/replaceCurrentCourier"
    }),
    openModalDeleteCourier(courier) {
      this.replaceCurrentCourier({ courier });
      this.replaceShowModalDeleteCourier({ status: true });
    }
  }
};
</script>
